import React from "react";
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

const questions = [
  {
    question: "¿Qué cubre un seguro para mascotas?",
    answer:
      "      Los seguros para mascotas ofrecen una variedad de coberturas, dependiendo del plan que elijas. Algunas de las coberturas más comunes incluyen: Gastos veterinarios: Esta cobertura paga las facturas por atención médica, como visitas al veterinario, exámenes, vacunas, medicamentos y cirugías. Accidentes y enfermedades: Esta cobertura paga los gastos por lesiones o enfermedades inesperadas, como fracturas, envenenamiento o enfermedades graves. Muerte accidental: Esta cobertura paga una cantidad fija si tu mascota muere debido a un accidente."
  },
  {
    question: "¿Vale la pena contratar un seguro para mascotas?",
    answer:
      "Si vale la pena o no contratar un seguro para mascotas es una decisión personal. Sin embargo, hay algunos factores que debes considerar al tomar tu decisión: El costo de la atención veterinaria: La atención veterinaria puede ser costosa, especialmente si tu mascota necesita atención especializada o cirugía. Un seguro para mascotas puede ayudarte a cubrir estos costos."
  },
  {
    question: "¿Cómo puedo elegir un seguro para mascotas?",
    answer:
      "Al elegir un seguro para mascotas, es importante comparar diferentes planes y coberturas para encontrar el mejor para ti y tu mascota. También debes leer detenidamente la póliza para asegurarte de entender lo que está cubierto y lo que no."
  },
];

const Faqs = () => {
  return (
    <section className="section faqs-dd bg-light edge top-left">
      <div className="shapes-container">
        <div className="absolute icon">
          <Fade top left>
            <FontAwesomeIcon icon={["fas", "question"]} />
          </Fade>
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">Preguntas Frecuentes FAQ</h2>
          <p className="lead">¿Quieres saber más sobre nuestros servicios?</p>
        </div>

        <Row>
          <Col lg="8" className="mx-lg-auto">
            {questions.map((faq, i) => (
              <Fade bottom key={i}>
                <div className="card shadow-box shadow-hover mb-3" key={i}>
                  <div className="card-header py-3">
                    <Link
                      to="#"
                      className="card-title collapsed"
                      id={`toggler-${i}`}
                    >
                      {faq.question}
                    </Link>
                  </div>

                  <UncontrolledCollapse toggler={`#toggler-${i}`}>
                    <div className="card-body">{faq.answer}</div>
                  </UncontrolledCollapse>
                </div>
              </Fade>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;
