import React, { useState } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PricingPlan from "./PricingPlan.jsx";
import WhatsappButton from "components/WhatsappButton.jsx";

const plans = [
  {
    name: "plan bronce",
    desc: "Valor asaegurado de $1.700.000",
    class: "order-md-first",
    price: { monthly: 99000, yearly: 1105800 },
  },
  {
    name: "plan plata",
    desc: "Valor asaegurado de $3.500.000",
    price: { monthly: 140000, yearly: 1629600 },
    best: true,
  },
  {
    name: "plan oro",
    desc: "Valor asaegurado de $6.000.000",
    class: "order-md-last",
    price: { monthly: 190000, yearly: 2211600 },
  },
];

const Pricing = (props) => {
  const [basis, setBasis] = useState("monthly");

  return (
    <section className="section pricing-plans">
      <div className="shapes-container overflow-clear">
        <div className="shape shape-1" />
        <div className="shape shape-2" />
        <div className="shape shape-3" />
        <div className="shape shape-4" />

        <div className="icon-holder">
          <i className="icon pe pe-7s-cash pe-3x" />

          <FontAwesomeIcon
            icon={["fas", "dollar-sign"]}
            className="icon fa-3x"
          />

          <i className="icon pe pe-7s-piggy pe-3x" />

          <i className="icon pe pe-7s-cart pe-3x" />

          <FontAwesomeIcon
            icon={["far", "credit-card"]}
            className="icon fa-3x"
          />

          <FontAwesomeIcon
            icon={["far", "money-bill-alt"]}
            className="icon fa-3x"
          />
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <span className="rounded-pill shadow-box bold py-2 px-4">
            <FontAwesomeIcon
              icon={["far", "lightbulb"]}
              className="text-primary mr-2"
            />
            <span className="text-primary">Protege</span> a los que quieres
          </span>
          <h2 className="mt-3 heading-line">Tarifas Transparentes</h2>
          <p className="lead text-muted">
            Adquiere la protección que necesitas para tus mascotas, con
            cobertura médica y beneficios adicionales.{" "}
            <span className="highlight">Sin costos ocultos</span>
          </p>

          <ButtonGroup>
            <Button
              color="secondary"
              onClick={(e) => setBasis("monthly")}
              active={basis === "monthly"}
            >
              Mensual
            </Button>

            <Button
              color="secondary"
              onClick={(e) => setBasis("yearly")}
              active={basis === "yearly"}
            >
              Anual
            </Button>
          </ButtonGroup>
        </div>

        <Row className="no-gutters align-items-center pricing-plans-options">
          {plans.map((plan, i) => (
            <PricingPlan {...plan} basis={basis} key={i} />
          ))}
        </Row>
      </Container>

      <Container className="pt-0 border-bottom">
        <Row className="align-items-center">
          <Col md="8">
            <div className="text-center text-md-left">
              <h4 className="bold accent">
                ¿No estás seguro de qué es mejor para ti?
              </h4>
              <p className="mt-0">
                Al afiliarte a uno de nuestros planes obtienes acceso a precios inmejorables de nuestra tienda en la aplicación.{" "} 
                Puedes empezar gratis, siempre y cuando te enamores de nuestra
                App usted puede decidir qué plan se adapta mejor a sus
                necesidades.{" "}
                <span className="italic dotted">
                  ¡No hay tarjetas de crédito requeridas!
                </span>
              </p>
            </div>
          </Col>

          <Col md="4" className="text-center text-md-right">
          <a href="https://wa.me/573226805080?text=Hola, me gustaría saber más sobre sus planes de seguro para mascotas." class="btn btn-success">
              <img width={100} src={require("assets/img/whatsapp.jpg")} alt="whatsapp" />
              </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;
