import React from "react";
import { Container, Row, Col, Nav } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import tiendas from "assets/img/tiendas.png";
import tiendas2 from "assets/img/tiendas2.png";

const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 }
];

const Heading = () => {
  return (
    <header className="header alter1-header section text-contrast">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="6">
            <span className="rounded-pill shadow-box bg-contrast text-dark bold py-2 px-4">
              <FontAwesomeIcon
                icon={["far", "lightbulb"]}
                className="text-primary mr-2"
              />
              <span className="text-primary">Protege </span> a los que quieres
            </span>

            <h1 className="text-contrast display-4 display-md-3">
              <span className="bold">PetSafe Seguros</span>
            </h1>
            <p className="lead bold">Seguros &amp; Cobertura médica para mascotas</p>

            <p className="lead">
              PetSafe Seguros es una aseguradora de mascotas que
              ofrece una variedad de servicios para proteger a tus mascotas.
              Con PetSafe Seguros, puedes asegurar a tus mascotas y obtener
              cobertura médica para ellas con benericios adicionales incluidos como descuentos insuperables en nuestra tienda online, baño y peluquería mensual asi como servicios médicos.{" "}              
            </p>

            <h2>Descargala aquí</h2>
            <img src={tiendas} width={100} alt="..." />
            <img src={tiendas2} width={100} alt="..." />
            {/* <Nav className="my-5" tag="nav">
              <a
                href="#!"
                className="btn btn-rounded btn-alternate mr-2 mr-md-5"
              >
                Comprar
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="ml-2"
                />
              </a>
              <a
                href="#"
                className="btn btn-rounded btn-contrast scrollto"
              >
                Conoce más
              </a>
            </Nav> */}
          </Col>

          <Col md="6">
            <div className="iphone front mx-auto">
              <div className="screen shadow-box">
                <img src={require("assets/img/screens/app/9.png")} alt="..." />
              </div>

              <div className="notch" />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
